// import EmergencyModalHOC from 'HoC/EmergencyModalHOC';
import {CDWProps, CDWPropsContext} from 'context/cdwPropsContext';
import {DataPrivacyContext, DataPrivacyProps} from 'context/dataPrivacyContext';
import {InfoTextProps, InfoTextPropsContext} from 'context/infoTextPropsContext';
import {NavProps, NavPropsContext} from 'context/navPropsContext';

import {SharedSeoComponent} from 'lib/api/strapi';

import ConsentManager from 'components/section-components/ConsentManager';
import Seo from 'components/strapi-components/Seo';
import { NewFeaturesModalProps, NewFeaturesModalPropsContext } from 'context/NewFeaturesModalContext';
import { ContactOptionsModalProps, ContactOptionsModalPropsContext } from 'context/ContactOptionsModalPropsContext';

export type TDefaultProps = {
  cdwProps: CDWProps;
  navProps: NavProps;
  infoTextProps: InfoTextProps;
  dataPrivacyProps: DataPrivacyProps;
  newFeaturesModalProps: NewFeaturesModalProps;
  defaultContactOptionsProps: ContactOptionsModalProps;
  seo: SharedSeoComponent;
};
export const DefaultPagePropsProvider = ({
  defaultProps,
  children,
}: {
  defaultProps: TDefaultProps;
  children: string | JSX.Element | JSX.Element[] | '() => JSX.Element';
}) => {
  const {navProps, cdwProps, infoTextProps, dataPrivacyProps, seo,newFeaturesModalProps, defaultContactOptionsProps} = defaultProps;
  return (
    <>
      {seo ? <Seo seoData={seo} /> : null}
      <NavPropsContext.Provider value={navProps}>
        <CDWPropsContext.Provider value={cdwProps}>
          <InfoTextPropsContext.Provider value={infoTextProps}>
            <DataPrivacyContext.Provider value={dataPrivacyProps}>
              <NewFeaturesModalPropsContext.Provider value={newFeaturesModalProps}>
                <ContactOptionsModalPropsContext.Provider value={defaultContactOptionsProps}>
                  {/* <EmergencyModalHOC> */}
                  <ConsentManager>{children}</ConsentManager>
                  {/* </EmergencyModalHOC> */}
              </ContactOptionsModalPropsContext.Provider>
              </NewFeaturesModalPropsContext.Provider>
            </DataPrivacyContext.Provider>
          </InfoTextPropsContext.Provider>
        </CDWPropsContext.Provider>
      </NavPropsContext.Provider>
    </>
  );
};
