import {useEffect, useReducer} from 'react';
import {useCookies} from 'react-cookie';

import {customInstance} from 'lib/api/mutator/custom-instance-backend';

export interface IConsentState {
  [key: string]: boolean;
}
export interface IReducerState {
  serviceState: IConsentState;
  isInitial: boolean;
}
interface UcCmp {
  updateServicesConsents(services: {id: string; consent: boolean}[]): Promise<void>;
  saveConsents(): Promise<void>;
  showFirstLayer(): Promise<void>;
  showSecondLayer(): Promise<void>;
  cmpController: {
    consent: {
      type: 'EXPLICIT' | 'IMPLICIT';
    };
    dps: {
      services: {
        [serviceId: string]: {
          consent: {
            given: boolean;
            type: 'EXPLICIT' | 'IMPLICIT';
          };
        };
      };
    };
  };
}
declare global {
  interface Window {
    __ucCmp?: UcCmp;
  }
}
const initialState: IReducerState = {serviceState: {}, isInitial: true};
const consentIdToNamesMapping = {
  'BJz7qNsdj-7': 'youtube',
  S1pcEj_jZX: 'maps',
  HkocEodjb7: 'trafficCookie',
  BJ59EidsWQ: 'tagManager',
  BJRdcNsuibm: 'belboon',
  'Brr-5p_FxgJEi9': 'partner',
};
export type ServiceNamesEnum =
  | 'youtube'
  | 'maps'
  | 'trafficCookie'
  | 'tagManager'
  | 'belboon'
  | 'partner';
export type ServiceIds = keyof typeof consentIdToNamesMapping;
export type ServiceNames = ServiceNamesEnum;
export type IInvertedConsentIdToNamesMapping = {
  [key in ServiceNames]: ServiceIds;
};
const invertedConsentIdToNamesMapping = Object.entries(consentIdToNamesMapping).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {},
) as IInvertedConsentIdToNamesMapping;

const consentReducer = (state: IReducerState, action) => {
  let isInitial = state.isInitial;
  switch (action.type) {
    case 'UPDATE_CONSENT_FROM_EVENT':
      let consentStateFromEvent = mapKeysToConsentState(action.payload);
      if (action?.hasOwnProperty('isInitial')) {
        isInitial = action.isInitial as boolean;
      }

      return {serviceState: consentStateFromEvent, isInitial: state.isInitial} as IReducerState;
    case 'UPDATE_CONSENT_FROM_GLOBAL_OBJECT':
      let consentStateFromGlobalObject = mapKeysToConsentState(action.payload);
      if (action?.hasOwnProperty('isInitial')) {
        isInitial = action.isInitial as boolean;
      }
      return {serviceState: consentStateFromGlobalObject, isInitial} as IReducerState;
    case 'UPDATE_IS_INITIAL':
      return {...state, isInitial: action.isInitial};
    default:
      return state;
  }
};
const mapKeysToConsentState = services =>
  Object.keys(services).reduce((acc, key) => {
    if (typeof services[key]?.consent === 'object') {
      acc[key] = services[key].consent?.given;
      if (consentIdToNamesMapping[key]) {
        acc[consentIdToNamesMapping[key]] = services[key].consent?.given;
      }
    } else {
      acc[key] = services[key].consent;
      if (consentIdToNamesMapping[key]) {
        acc[consentIdToNamesMapping[key]] = services[key].consent;
      }
    }
    return acc;
  }, {}) as IConsentState;

const useConsentHandler = () => {
  const [reducerState, dispatch] = useReducer(consentReducer, initialState);
  const [cookies, setCookie, _removeCookie] = useCookies(['privacy_consent']);
  const updateStateFromGlobalObject = () => {
    if (typeof window !== 'undefined' && window?.__ucCmp) {
      if (window?.__ucCmp?.cmpController?.dps?.services) {
        dispatch({
          type: 'UPDATE_CONSENT_FROM_GLOBAL_OBJECT',
          payload: window?.__ucCmp?.cmpController?.dps?.services,
          isInitial: window?.__ucCmp?.cmpController?.consent?.type === 'IMPLICIT',
        });
      }
    }
    return typeof window !== 'undefined' && window?.__ucCmp;
  };
  const openModal = () => {
    if (typeof window !== 'undefined') {
      window?.__ucCmp.showSecondLayer();
    }
  };
  const setConsentStateForKey = (key: ServiceNames, value: boolean) => {
    if (typeof window !== 'undefined' && window?.__ucCmp?.updateServicesConsents) {
      const servicesToUpdate = [
        {
          consent: value,
          id: invertedConsentIdToNamesMapping[key],
        },
      ];
      window?.__ucCmp
        .updateServicesConsents(servicesToUpdate)
        .then(() => window.__ucCmp.saveConsents());
    }
  };
  useEffect(() => {
    const callback = function (e) {
      if (e?.detail?.services) {
        dispatch({type: 'UPDATE_CONSENT_FROM_EVENT', payload: e?.detail?.services});
        setTimeout(() => {
          dispatch({type: 'UPDATE_IS_INITIAL', isInitial: false});
        }, 1000);
      }
    };
    window.addEventListener('UC_CONSENT', callback);
    return () => {
      window.removeEventListener('UC_CONSENT', callback);
    };
  }, []); // Empty dependency array to run only once after the component mounts
  useEffect(() => {
    // Initialize the state from localStorage on component mount
    let hasStateBeenFound = updateStateFromGlobalObject();
    // Define an event handler for changes to local storage
    let interval;
    if (!hasStateBeenFound) {
      interval = setInterval(() => {
        hasStateBeenFound = updateStateFromGlobalObject();
        if (hasStateBeenFound) {
          clearInterval(interval);
        }
      }, 200);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    setCookie('privacy_consent', JSON.stringify(reducerState?.serviceState), {
      path: '/',
    });
  }, [reducerState]);
  useEffect(() => {
    if (!reducerState?.isInitial) {
      customInstance({url: window.location.href, noSpinner: true}).catch(err => {
        console.log(err);
      });
    }
  }, [reducerState?.isInitial]);
  return {
    state: reducerState?.serviceState,
    setConsentStateForKey,
    openModal,
    isInitial: reducerState.isInitial,
  };
  // return reducerState as {[key: string]: boolean};
};

export default useConsentHandler;
