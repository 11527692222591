import { ContactOptionsModal } from 'lib/api/strapi';
import {createContext} from 'react';

export interface LocaleContactOptionsModalProps {
  [key: string]: ContactOptionsModalProps;
}

export type ContactOptionsModalProps = ContactOptionsModal

export const ContactOptionsModalPropsContext = createContext({} as ContactOptionsModal);
