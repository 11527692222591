import {GOOGLE_TAG_MANAGER_ID} from 'config/index';
import {useTrafficSourceCookie} from 'hooks/useTrafficSourceCookie';
import {createContext, useCallback, useEffect} from 'react';
import {withCookies} from 'react-cookie';
import TagManager from 'react-gtm-module';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {NewFeaturesModal} from '../NewFeaturesModal';
import useConsentHandler, {IConsentState, ServiceNames} from './useConsentManager';

declare global {
  interface Window {
    dataLayer: any[];
    environment: {[key: string]: any};
    refetchEnvironment: any;
  }
}
export const ConsentContext = createContext({
  openModal: () => {},
  isInitial: true,
  setConsentStateForKey: () => {},
  pushDataLayer: () => {},
  state: {},
} as {
  state: IConsentState;
  setConsentStateForKey: (key: ServiceNames, value: boolean) => void;
  pushDataLayer: Function;
  openModal: Function;
  isInitial: boolean;
});

const ConsentManager = ({children}) => {
  const {state, setConsentStateForKey, openModal, isInitial} = useConsentHandler();

  useTrafficSourceCookie(state);

  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
  };

  useEffect(() => {
    if (state.tagManager) {
      TagManager.initialize(tagManagerArgs);
    }
  }, [state.tagManager]);

  const pushDataLayer = useCallback(
    data => {
      if (state.tagManager) {
        if (window && typeof window === 'object') {
          window.dataLayer = window?.dataLayer || [];
          window.dataLayer.push(data);
        }
      }
    },
    [state.tagManager],
  );

  return (
    <ConsentContext.Provider
      value={{
        state,
        setConsentStateForKey,
        openModal,
        pushDataLayer,
        isInitial,
      }}
    >
      <NewFeaturesModal />
      {children}
    </ConsentContext.Provider>
  );
};

export default withErrorBoundary(withCookies(ConsentManager), 'ConsentManager');
